function edit_content_key(vars) {
    var $ = jQuery.noConflict();
    var form = $('form.contentKeyForm');

    if(form.find('input#id').length == 0) {
        form.append('<input type="hidden" id="id" name="id" value="0" />');
    }
    form.find('input#id').val(vars.id);

    form.find('input#name').val(vars.name);
    form.find(':input#parent').val(vars.parent_id);

    form.find('.cancel-button').toggle();

    return false;
}
function reset_content_key_form(sender) {
    var $ = jQuery.noConflict();
    var elm = $(sender);
    elm.toggle();
    var form = elm.parents('form');
    form.find('input#id').remove();
}
function delete_content_key(sender, vars) {
    var $ = jQuery.noConflict();
    var elm = $(sender);
    var answer = confirm("Weet je zeker dat je "+vars.name+" en gekoppelde content wilt verwijderen?");
    if(answer === true) {
        $.post('/ajax/admin/deleteContentKey', vars, function(data) {
            elm.parents('tr').remove();
        });
    }
}
function get_content_for(sender) {
    var $ = jQuery.noConflict();
    var elm = $(sender);
    var id = elm.val();
    var w = $('.key-content-wrapper');

    $.get('/content/getContentForKey/'+id, function(html) {
        w.html(html);
        w.find('textarea').each(function() {
            tinymce.EditorManager.editors = [];
            tinymce.init({
                selector: '#'+$(this).attr('id'),
                //inline: true,
                plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table contextmenu paste'
                ],
                toolbar: 'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image',
                //init_instance_callback: callback
            });
        });

    })
}