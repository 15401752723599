var neurogramTypeAjax = 0;
function loadThisTypeContents(sender) {
    var $ = jQuery.noConflict();
    var a = $(sender);
    var li = a.parents('li');
    var ul = li.parents('ul');
    ul.find('.active').removeClass('active');
    li.addClass('active');
    var title = a.text();
    $('.type-title').html(title);
    var url = a.attr('href');

    if(neurogramTypeAjax != 0) {
        neurogramTypeAjax.abort();
    }
    neurogramTypeAjax = $.ajax({
        url: url,
        type: 'GET',
        'dataType': 'JSON',
        success: function(data) {
            var w = $('.editor-wrapper');

            var i = 0;
            for(var key in data) {
                var row = data[key];
                var title = row.title;
                var content = row.content;
                var html_id = 'content-'+key;
                var cce = w.children(':first');

                if(w.children('#'+html_id).length == 0) {
                    if(i > 0) {
                        cce.clone(true,true).appendTo(w);
                        cce = w.children(':last');
                    }
                    cce.attr('id', html_id);
                } else {
                    cce = w.children('#'+html_id);
                }

                cce.customEditor({
                    content: content,
                    title: title,
                    title_not_editable: true,
                    post_url: url
                });

                i++;
            }
        },
        error: function(e, str) {
            console.log(e);
        }
    });
    return false;
}