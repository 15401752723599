(function ( $ ) {
    var class_prefix = "custom-editor-";
    $.fn.customEditor = function(params) {

        var prefix = function(str, selector) {
            return (selector === true ? '.' : '')+class_prefix+str;
        };

        $(this).each(function() {
            var elm = $(this);
            var w, uid;

            elm.empty();

            //Init the custom editor stuff
            uid = get_unique_id();
            elm.html('<div class="'+prefix('wrapper')+'" id="'+uid+'"></div>');
            w = elm.find(prefix('wrapper', true));

            w.html('<h1 class="'+prefix('title')+'"></h1><div class="'+prefix('content')+'"></div>');

            //Set the title
            var title_wrapper = w.find(prefix('title',true));
            if(params.title != undefined) {
                title_wrapper.html(params.title);
            }
            //Set the content
            var content_wrapper = w.find(prefix('content',true));
            if(params.content != undefined) {
                content_wrapper.html(params.content);
            }

            var callback = function(editor) {
                editor.on('blur', function (e) {

                    var data = {
                        content: content_wrapper.html()
                    };
                    if(params.title_not_editable == undefined) {
                        data.title = title_wrapper.text();
                    }

                    $.post(params.post_url, data, function(data) {
                        console.log(data);
                    });
                });
            };

            if(params.title_not_editable == undefined) {
                tinymce.init({
                    selector: '#'+uid+' h1.'+prefix('title'),
                    inline: true,
                    toolbar: 'undo redo',
                    menubar: false,
                    init_instance_callback: callback
                });
            }


            tinymce.init({
                selector: '#'+uid+' .'+prefix('content'),
                inline: true,
                plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table contextmenu paste'
                ],
                toolbar: 'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image',
                init_instance_callback: callback
            });
        });
        return this;
    };

}( jQuery ));