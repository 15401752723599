function get_unique_id()
{
    var $ = jQuery.noConflict();
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for( var i=0; i < 5; i++ )
        text += possible.charAt(Math.floor(Math.random() * possible.length));

    if($('#'+text).length>0) {
        return get_unique_id();
    }
    return text;
}